export default [
  {
    path: '/facility/list',
    name: 'facility-list',
    component: () => import('@/views/place/facility/list/FacilityList.vue'),
    meta: {
      resource: 'Facility',
      action: 'read',
    },
  },
  {
    path: '/departmentofhealth/list',
    name: 'departmentofhealth-list',
    component: () => import('@/views/place/department-of-health/list/DepartmentOfHealthList.vue'),
    meta: {
      resource: 'DepartmentOfHealth',
      action: 'read',
    },
  },
  {
    path: '/drug-store/list',
    name: 'drug-store-list',
    component: () => import('@/views/place/drugstore/list/DrugStoreList.vue'),
    meta: {
      resource: 'Pharmacy',
      action: 'read',
    },
  },

];
