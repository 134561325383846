export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/view/ProfileView.vue'),
    meta: {
      resource: 'Profile',
      action: 'read',
    },
  },
];
