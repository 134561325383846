/* eslint-disable no-tabs */
/* eslint-disable indent */
export default {
	// This will be prefixed in authorization header with token
	// e.g. Authorization: Bearer <token>
	tokenType: 'Bearer',

	// Value of this property will be used as key to store JWT token in storage
	storageTokenKeyName: 'accessToken',
	storageRefreshTokenKeyName: 'refreshToken',

	// Endpoints utils
	utilsPricingEndpoint: '/api/v1/utils/pricing',
	utilsCountriesEndpoint: '/api/v1/utils/countries',
	utilsProvinceEndpoint: '/local/province-list',
	utilsDistrictEndpoint: '/local/district-list',
	utilsWardEndpoint: '/local/ward-list',
	utilsVehicleTypesEndpoint: '/api/v1/utils/vehicle-types',
	utilsPaymentPeriodsEndpoint: '/api/v1/utils/payment-periods',
	utilsGendersEndpoint: '/api/v1/utils/genders',
	utilsUnitsEndpoint: '/api/v1/utils/units',
	utilsMeterTypesEndpoint: '/api/v1/utils/meter_types',
	utilsAbilitiesEndpoint: '/api/v1/utils/abilities',
	utilsCalculateQuantityEndpoint: '/api/v1/utils/calculate-quantity-with-date-range',

	// Endpoints
	loginEndpoint: '/user/login',
	getVneIdTokenEndpoint: '/user/get-vneid-token',
	registerEndpoint: '/api/v1/user/register',
	refreshEndpoint: '/api/v1/user/refresh-token',
	logoutEndpoint: '/api/v1/user/logout',

	// Endpoint user
	userProfileEndpoint: '/user/profile',
	userProfileUpdateEndpoint: '/user/update-profile',
	userChangePasswordEndpoint: '/user/change-account-password',
	userVerifyEmailEndpoint: '/api/v1/user/verify-email',
	userRequestVerifyEmailEndpoint: '/api/v1/user/request-verify-email',
	userRequestMissCallOTPEndpoint: '/api/v1/user/request-miss-call-otp',
	userRequestOTPForgotPasswordByEmailEndpoint: '/user/forgot-password',
	userResetPasswordWithOTPEndpoint: '/api/v1/user/reset-password-with-otp',
	userResetPasswordWithEmailEndpoint: '/user/update-forgot-password',
	userVerifyPhoneEndpoint: '/api/v1/user/verify-phone',
	userSetPasswordEndpoint: '/user/update-account-password',

	// Endpoint dashboard
	dashboardEndpoint: '/v1/dashboard/get-total-count',
	dashboardUserEndpoint: '/v1/dashboard/get-report-user',

	// Plan
	planListEndpoint: '/api/v1/plan/list',
	planDetailEndpoint: '/api/v1/plan/detail',

	// Order
	orderListEndpoint: '/api/v1/order/list',
	orderCreateEndpoint: '/api/v1/order/create',
	orderUpdateEndpoint: '/api/v1/order/update',
	orderDeleteEndpoint: '/api/v1/order/delete',

	// Doctor
	doctorListingEndPoint: '/v1/doctor/get-paging',
	doctorDetailEndPoint: '/doctor',
	doctorListEndPoint: '/doctor/list',
	doctorPersonalListEndPoint: '/doctor/suggest',
	doctorListForMedicalFacilityEndPoint: '/doctor/listing-for-admin-medical-facility',
	doctorCreateEndPoint: '/doctor/add',
	doctorDownloadImportTemplateEndPoint: '/doctor/generate-import-template',
	doctorImportEndPoint: '/doctor/import',
	doctorCreateFormedicalFacilityEndPoint: '/doctor/add-for-medical-facility',
	doctorUpdateEndPoint: '/doctor/',
	doctorUpdateFormedicalFacilityEndPoint: '/doctor/edit-for-medical-facility/',

	// Patient
	patientListEndPoint: '/v1/customer/get-paging',
	patientSelectListEndPoint: '/customer/select-list',
	patientListForMedicalFacilityEndPoint: '/customer/listing-for-admin-medical-facility',
	patientCreateEndPoint: '/customer/create',
	patientUpdateEndPoint: '/customer',
	patientDeleteEndPoint: '/customer',
	patientExportEndPoint: '/customer/export',

	// Customer Group
	customerGroupListEndPoint: '/customer-group/select-list',

	// department-of-health
	departmentOfHealthListEndPoint: '/v1/department-of-health/get-paging',
	departmentOfHealthCreateEndPoint: '/v1/department-of-health/create',
	departmentOfHealthUpdateEndPoint: '/v1/department-of-health/update',
	departmentOfHealthDeleteEndPoint: '/v1/department-of-health/delete',

	getUsersAdminDepartmentOfHealthEndPoint: '/v1/department-of-health/user/get-list',
	createUserAdminDepartmentOfHealthEndPoint: '/v1/department-of-health/user/create',
	updateUserAdminDepartmentOfHealthEndPoint: '/v1/department-of-health/user/update',
	deleteUserAdminDepartmentOfHealthEndPoint: '/v1/department-of-health/user/delete',

	// Medical Facility
	facilityListEndPoint: '/medical-facility/listing',
	facilitySuggestEndPoint: '/medical-facility/listing',
	facilityUpdateEndPoint: '/medical-facility',
	facilityDeleteEndPoint: '/medical-facility',
	facilityCreateEndPoint: '/medical-facility/add',

	facilityAdminListEndPoint: '/medical-facility/admin-listing',
	facilityAdminCreateEndPoint: '/user/add-medical-facility-admin',
	facilityAdminUpdateEndPoint: '/user',

	// Pharmacy
	pharmacyListEndPoint: '/pharmacy/listing',
	pharmacyCreateEndPoint: '/pharmacy/add',
	pharmacyUpdateEndPoint: '/pharmacy',
	pharmacyDeleteEndPoint: '/pharmacy',

	// Attendance
	attendanceListEndPoint: '/v1/work-log/get-paging',
	attendanceExportEndPoint: '/work-log/export',

	// Meeting
	meetingListEndPoint: '/v1/medical-file/get-paging',
	meetingListForMedicalFacilityEndPoint: '/meeting/listing-for-medical-facility',
	meetingListFromWorkLogEndPoint: '/meeting/work-log-listing',

	// Prescription
	prescriptionListEndPoint: '/v1/prescription/get-paging',

	// Advertisement
	advertisementListEndPoint: '/advertisement/listing',
	advertisementCreateEndPoint: '/advertisement/add',
	advertisementUpdateEndPoint: '/advertisement',
	advertisementDeleteEndPoint: '/advertisement',
	// Payment
	paymentListEndPoint: '/payment/listing',

	// Promotion
	promotionListEndPoint: '/promotion-policy/listing',
	promotionCreateEndPoint: '/promotion-policy/add',
	promotionUpdateEndPoint: '/promotion-policy',
	promotionDeleteEndPoint: '/promotion-policy',

	// Voucher
	voucherListEndPoint: '/voucher/listing-for-admin',
	voucherListForMedicalFacilityEndPoint: '/voucher/listing',
	voucherCreateEndPoint: '/voucher/add',
	voucherUpdateEndPoint: '/voucher',

	// Code
	codeListEndPoint: '/pricing-code/listing',

	// Pricing
	pricingListEndPoint: '/pricing/listing',
	pricingCreateEndPoint: '/pricing/add',
	pricingUpdateEndPoint: '/pricing',
	pricingDeleteEndPoint: '/pricing',
	pricingSuggestEndPoint: '/pricing/suggest',

	// Department
	departmentListEndPoint: '/specialist-category/listing',
	departmentSuggestEndPoint: '/specialist-category/suggest',
	departmentCreateEndPoint: '/specialist-category/add',
	departmentUpdateEndPoint: '/specialist-category',
	departmentDeleteEndPoint: '/specialist-category',

	// Specialist
	specialistListEndPoint: '/specialist-sub-category/listing',
	specialistSuggestEndPoint: '/specialist-sub-category/suggest',
	specialistCreateEndPoint: '/specialist-sub-category/add',
	specialistUpdateEndPoint: '/specialist-sub-category',
	specialistDeleteEndPoint: '/specialist-sub-category',

	// Blood
	bloodListEndPoint: '/blood/listing',
	bloodCreateEndPoint: '/blood/add',
	bloodUpdateEndPoint: '/blood',
	bloodDeleteEndPoint: '/blood',

	// Service
	serviceListEndPoint: '/providing-service/listing',
	serviceCreateEndPoint: '/providing-service/add',
	serviceUpdateEndPoint: '/providing-service',
	serviceDeleteEndPoint: '/providing-service',

	// Disease
	diseaseListEndPoint: '/diseases/listing',
	diseaseCreateEndPoint: '/diseases/add',
	diseaseUpdateEndPoint: '/diseases',
	diseaseDeleteEndPoint: '/diseases',

	// Drug
	drugListEndPoint: '/drug/listing',
	drugCreateEndPoint: '/drug/add',
	drugUpdateEndPoint: '/drug',
	drugDeleteEndPoint: '/drug',

	// Faq
	faqListEndPoint: '/pre-medical-question/listing',
	faqCreateEndPoint: '/pre-medical-question/add',
	faqUpdateEndPoint: '/pre-medical-question',
	faqDeleteEndPoint: '/pre-medical-question',

	// File
	fileUploadSingleEndPoint: '/media/upload-multi-size',

	// Exp
	expListEndPoint: '/experience/listing-for-admin',
	expCreateEndPoint: '/experience/add-for-admin',
	expUpdateEndPoint: '/experience/update-for-admin',
	expDeleteEndPoint: '/experience/delete-for-admin',

	// Education
	educationListEndPoint: '/education/listing-for-admin',
	educationCreateEndPoint: '/education/add-for-admin',
	educationUpdateEndPoint: '/education/update-for-admin',
	educationDeleteEndPoint: '/education/delete-for-admin',

	// Education
	configurationListEndPoint: '/configuration/listing',
	configurationCreateEndPoint: '/configuration/add',
	configurationUpdateEndPoint: '/configuration',
	configurationDeleteEndPoint: '/configuration',

	// Zoom User
	zoomUserListEndPoint: "/v1/zoom-user/get-paging",
	zoomUserSynchronizationEndPoint: "/v1/zoom-user/data-synchronization",
	zoomUserCreateEndPoint: "/v1/zoom-user/create",
	zoomUserUpdateEndPoint: "/v1/zoom-user/update",
	zoomUserDeleteEndPoint: "/v1/zoom-user/delete",

	// Zoom User
	partnerListEndPoint: "/v1/partner/get-paging",
	partnerCreateEndPoint: "/v1/partner/create",
	partnerUpdateUpdateEndPoint: "/v1/partner/update",
	partnerDeleteEndPoint: "/v1/partner/delete",

	// Endpoint role
	roleListEndpoint: '/role/listing',
	roleDetailEndpoint: '/v1/role',
	roleAbilitiesEndpoint: '/v1/role/abilities',
	roleCreateEndpoint: '/role/add',
	roleUpdateEndpoint: '/role',
	roleDeleteEndpoint: '/role',

	// Endpoint manager
	managerListEndpoint: '/v1/manager',
	managerDetailEndpoint: '/v1/manager',
	managerCreateEndpoint: '/v1/manager',
	managerUpdateEndpoint: '/v1/manager',
	managerDeleteEndpoint: '/v1/manager',

	// Endpoint permission
	permissionListEndPoint: '/permission/listing',

	// rating
	ratingListEndpoint: '/v1/doctor/get-review-details-paging',
	// department of health setting
	departmentOfhealthSettingGetEndPoint: '/v1/department-of-health/get-setting',
	departmentOfhealthSettingSaveEndPoint: '/v1/department-of-health/save-setting',

	// member
	memberListOfCustomer: '/customer/members',

};
