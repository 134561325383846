export default [
  {
    path: '/advertisement',
    name: 'advertisement-list',
    component: () => import('@/views/settings/advertisement/list/AdvertisementList.vue'),
    meta: {
      resource: 'SettingAdvertisement',
      action: 'read',
    },
  },
  {
    path: '/configuration',
    name: 'configuration-list',
    component: () => import('@/views/settings/configuration/list/ConfigurationList.vue'),
    meta: {
      resource: 'SettingConfiguration',
      action: 'read',
    },
  },
  {
    path: '/departments',
    name: 'department-list',
    component: () => import('@/views/settings/general/department/list/DepartmentList.vue'),
    meta: {
      resource: 'Department',
      action: 'read',
    },
  },
  {
    path: '/specialist',
    name: 'specialist-list',
    component: () => import('@/views/settings/general/specialist/list/SpecialistList.vue'),
    meta: {
      resource: 'Specialist',
      action: 'read',
    },
  },
  {
    path: '/blood-group',
    name: 'blood-group-list',
    component: () => import('@/views/settings/general/blood-group/list/BloodGroupList.vue'),
    meta: {
      resource: 'Blood',
      action: 'read',
    },
  },
  {
    path: '/services',
    name: 'service-list',
    component: () => import('@/views/settings/general/service/list/ServiceList.vue'),
    meta: {
      resource: 'Service',
      action: 'read',
    },
  },
  {
    path: '/diseases',
    name: 'disease-list',
    component: () => import('@/views/settings/general/disease/list/DiseaseList.vue'),
    meta: {
      resource: 'Disease',
      action: 'read',
    },
  },
  {
    path: '/drugs',
    name: 'drug-list',
    component: () => import('@/views/settings/general/drug/list/DrugList.vue'),
    meta: {
      resource: 'Drug',
      action: 'read',
    },
  },
  {
    path: '/faq',
    name: 'faq-list',
    component: () => import('@/views/settings/general/faq/list/FaqList.vue'),
    meta: {
      resource: 'Faq',
      action: 'read',
    },
  },
  {
    path: '/provinces',
    name: 'province-list',
    component: () => import('@/views/settings/province/list/ProvinceList.vue'),
    meta: {
      resource: 'Setting',
      action: 'read',
    },
  },
  {
    path: '/districts',
    name: 'district-list',
    component: () => import('@/views/settings/district/list/DistrictList.vue'),
    meta: {
      resource: 'Setting',
      action: 'read',
    },
  },
  {
    path: '/wards',
    name: 'ward-list',
    component: () => import('@/views/settings/ward/list/WardList.vue'),
    meta: {
      resource: 'Setting',
      action: 'read',
    },
  },
  {
    path: '/roles',
    name: 'role-list',
    component: () => import('@/views/settings/permission/role/list/RoleList.vue'),
    meta: {
      resource: 'Setting',
      action: 'read',
    },
  },
  // {
  //   path: '/managers',
  //   name: 'manager-list',
  //   component: () => import('@/views/settings/permission/manager/list/ManagerList.vue'),
  //   meta: {
  //     resource: 'Setting',
  //     action: 'read',
  //   },
  // },
];
