export default [
  {
    path: '/pricing/list',
    name: 'pricing-list',
    component: () => import('@/views/subscription/pricing/list/PricingList.vue'),
    meta: {
      resource: 'Pricing',
      action: 'read',
    },
  },
  {
    path: '/code/list',
    name: 'code-list',
    component: () => import('@/views/subscription/code/list/CodeList.vue'),
    meta: {
      resource: 'Code',
      action: 'read',
    },
  },
  {
    path: '/voucher/list',
    name: 'voucher-list',
    component: () => import('@/views/subscription/voucher/list/VoucherList.vue'),
    meta: {
      resource: 'Voucher',
      action: 'read',
    },
  },
  {
    path: '/discount/list',
    name: 'discount-list',
    component: () => import('@/views/subscription/discount/list/DiscountList.vue'),
    meta: {
      resource: 'Discount',
      action: 'read',
    },
  },
  {
    path: '/payment/list',
    name: 'payment-list',
    component: () => import('@/views/subscription/payment/list/PaymentList.vue'),
    meta: {
      resource: 'Payment',
      action: 'read',
    },
  },

];
