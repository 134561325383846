/* eslint-disable indent */
/* eslint-disable no-tabs */
// eslint-disable-next-line import/no-cycle
import { getUserRole } from '@/auth/utils';
import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
	// Will be used by this service for making API calls
	axiosIns = null;

	// jwtConfig <= Will be used by this service
	jwtConfig = { ...jwtDefaultConfig };

	// For Refreshing Token
	isAlreadyFetchingAccessToken = false;

	// For Refreshing Token
	subscribers = [];

	constructor(axiosIns, jwtOverrideConfig) {
		this.axiosIns = axiosIns;
		this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

		// Request Interceptor
		this.axiosIns.interceptors.request.use(
			config => {
				// Get token from localStorage
				const accessToken = this.getToken();

				// If token is present add it to request's Authorization Header
				if (accessToken) {
					// eslint-disable-next-line no-param-reassign
					config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
				}
				return config;
			},
			error => Promise.reject(error),
		);

		// Add request/response interceptor
		this.axiosIns.interceptors.response.use(
			response => response,
			error => {
				// const { config, response: { status } } = error
				const { config, response } = error;
				const originalRequest = config;

				// if (status === 401) {
				if (response && response.status === 401) {
					if (!this.isAlreadyFetchingAccessToken) {
						this.isAlreadyFetchingAccessToken = true;
						this.refreshToken()
							.then(r => {
								this.isAlreadyFetchingAccessToken = false;

								// Update accessToken in localStorage
								this.setToken(r.data.access_token);
								this.setRefreshToken(r.data.refresh_token);

								this.onAccessTokenFetched(r.data.access_token);
								return true;
							})
							.catch(() => {
								// ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
								localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
								localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);

								// Remove userData from localStorage
								localStorage.removeItem('userData');
								this.onAccessTokenFetched(null);
								window.location.href = '/login';
								return false;
							});
					}
					const retryOriginalRequest = new Promise(resolve => {
						this.addSubscriber(accessToken => {
							// Make sure to assign accessToken according to your response.
							// Check: https://pixinvent.ticksy.com/ticket/2413870
							// Change Authorization header
							originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
							resolve(this.axiosIns(originalRequest));
						});
					});
					return retryOriginalRequest;
				}
				return Promise.reject(error);
			},
		);
	}

	onAccessTokenFetched(accessToken) {
		this.subscribers = this.subscribers.filter(callback => callback(accessToken));
	}

	addSubscriber(callback) {
		this.subscribers.push(callback);
	}

	getToken() {
		return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
	}

	getRefreshToken() {
		return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
	}

	setToken(value) {
		localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
	}

	setRefreshToken(value) {
		localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
	}

	login(...args) {
		return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
	}

	register(...args) {
		return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
	}

	refreshToken() {
		return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
			refresh_token: this.getRefreshToken(),
		});
	}

	// utils
	getPricing() {
		return this.axiosIns.get(this.jwtConfig.utilsPricingEndpoint);
	}

	getCountries() {
		return this.axiosIns.get(this.jwtConfig.utilsCountriesEndpoint);
	}

	getVehicleTypes() {
		return this.axiosIns.get(this.jwtConfig.utilsVehicleTypesEndpoint);
	}

	getGenders() {
		return this.axiosIns.get(this.jwtConfig.utilsGendersEndpoint);
	}

	getUnits() {
		return this.axiosIns.get(this.jwtConfig.utilsUnitsEndpoint);
	}

	getProvince(params) {
		return this.axiosIns.post(this.jwtConfig.utilsProvinceEndpoint, { params });
	}

	getDistrict(province) {
		return this.axiosIns.post(this.jwtConfig.utilsDistrictEndpoint, { province: province._id ? province._id : province.id, length: 1000 });
	}

	getWards(district) {
		return this.axiosIns.post(this.jwtConfig.utilsWardEndpoint, { district: district.id ? district.id : district._id, length: 1000 });
	}

	// notification
	createNotification(...args) {
		return this.axiosIns.post(this.jwtConfig.notificationCreateEndpoint, ...args);
	}

	updateNotification(...args) {
		return this.axiosIns.post(this.jwtConfig.notificationUpdateEndpoint, ...args);
	}

	getNotifications(params) {
		return this.axiosIns.get(this.jwtConfig.notificationListEndpoint, {
			params,
		});
	}

	getNotificationDetail(id) {
		return this.axiosIns.get(`${this.jwtConfig.notificationDetailEndpoint}/${id}`);
	}

	deleteNotifications(...args) {
		return this.axiosIns.post(this.jwtConfig.notificationDeleteEndpoint, ...args);
	}

	// user
	getUserProfile() {
		return this.axiosIns.post(this.jwtConfig.userProfileEndpoint);
	}

	updateUserProfile(data) {
		return this.axiosIns.post(`${this.jwtConfig.userProfileUpdateEndpoint}`, data);
	}

	verifyEmail(...args) {
		return this.axiosIns.post(this.jwtConfig.userVerifyEmailEndpoint, ...args);
	}

	getVneIdToken(data) {
		return this.axiosIns.post(this.jwtConfig.getVneIdTokenEndpoint, data);
	}

	verifyPhone(...args) {
		return this.axiosIns.post(this.jwtConfig.userVerifyPhoneEndpoint, ...args);
	}

	requestVerifyEmail(...args) {
		return this.axiosIns.post(this.jwtConfig.userRequestVerifyEmailEndpoint, ...args);
	}

	requestMissCallOTP(...args) {
		return this.axiosIns.post(this.jwtConfig.userRequestMissCallOTPEndpoint, ...args);
	}

	requestOTPByEmail(...args) {
		return this.axiosIns.post(this.jwtConfig.userRequestOTPForgotPasswordByEmailEndpoint, ...args);
	}

	resetPasswordWithOTP(...args) {
		return this.axiosIns.post(this.jwtConfig.userResetPasswordWithOTPEndpoint, ...args);
	}

	resetPasswordWithEmailCodeOTP(...args) {
		return this.axiosIns.post(this.jwtConfig.userResetPasswordWithEmailEndpoint, ...args);
	}

	changeUserPassword(...args) {
		return this.axiosIns.post(this.jwtConfig.userChangePasswordEndpoint, ...args);
	}

	setUserPassword(data) {
		return this.axiosIns.put(`${this.jwtConfig.userSetPasswordEndpoint}/${data._id}`, data);
	}

	// dashboard
	getDashboard() {
		return this.axiosIns.get(this.jwtConfig.dashboardEndpoint);
	}

	getDashboardUser(data) {
		return this.axiosIns.post(this.jwtConfig.dashboardUserEndpoint, data);
	}

	// Doctor
	getDoctors(params) {
		return this.axiosIns.get(this.jwtConfig.doctorListingEndPoint, { params });
	}

	getDoctorDetail(id) {
		return this.axiosIns.get(`${this.jwtConfig.doctorDetailEndPoint}/${id}`);
	}

	getDoctorImportTemplateFile() {
		return this.axiosIns.get(this.jwtConfig.doctorDownloadImportTemplateEndPoint);
	}

	importDoctors(...args) {
		return this.axiosIns.post(this.jwtConfig.doctorImportEndPoint, ...args);
	}

	getReviews(...args) {
		return this.axiosIns.post(this.jwtConfig.doctorListEndPoint, ...args);
	}

	getPersonalDoctors(...args) {
		// const role = getUserRole();
		// if (role && role.name === 'medical_facility') {
		//   return this.axiosIns.post(this.jwtConfig.doctorListForMedicalFacilityEndPoint, ...args);
		// }

		return this.axiosIns.post(this.jwtConfig.doctorPersonalListEndPoint, ...args);
	}

	createDoctor(...args) {
		const role = getUserRole();
		if (role && role.name === 'medical_facility') {
			return this.axiosIns.post(this.jwtConfig.doctorCreateFormedicalFacilityEndPoint, ...args);
		}
		return this.axiosIns.post(this.jwtConfig.doctorCreateEndPoint, ...args);
	}

	updateDoctor(data) {
		const role = getUserRole();
		if (role && role.name === 'medical_facility') {
			return this.axiosIns.put(`${this.jwtConfig.doctorUpdateFormedicalFacilityEndPoint}${data._id}`, data);
		}
		return this.axiosIns.put(`${this.jwtConfig.doctorUpdateEndPoint}${data._id}`, data);
	}

	// Patient
	getPatients(params) {
		// const role = getUserRole();
		// if (role && role.name === 'medical_facility') {
		// 	return this.axiosIns.post(this.jwtConfig.patientListForMedicalFacilityEndPoint, ...args);
		// }
		return this.axiosIns.get(this.jwtConfig.patientListEndPoint, { params });
	}

	getPatientListSelect(...args) {
		return this.axiosIns.post(this.jwtConfig.patientSelectListEndPoint, ...args);
	}

	createPatient(data) {
		return this.axiosIns.post(this.jwtConfig.patientCreateEndPoint, data);
	}

	updatePatient(data) {
		return this.axiosIns.put(`${this.jwtConfig.patientUpdateEndPoint}/${data._id}`, data);
	}

	deletePatient(id) {
		return this.axiosIns.delete(`${this.jwtConfig.patientDeleteEndPoint}/${id}`);
	}

	exportPatient(...args) {
		return this.axiosIns.post(`${this.jwtConfig.patientExportEndPoint}`, ...args, {
			responseType: 'blob',
		});
	}

	// Customer Group
	getCustomerGroups(...args) {
		return this.axiosIns.post(this.jwtConfig.customerGroupListEndPoint, ...args);
	}

	// department-of-health
	getDepartmentOfHealths(params) {
		return this.axiosIns.get(this.jwtConfig.departmentOfHealthListEndPoint, { params });
	}

	createDepartmentOfHealth(...args) {
		return this.axiosIns.post(this.jwtConfig.departmentOfHealthCreateEndPoint, ...args);
	}

	updateDepartmentOfHealth(data) {
		return this.axiosIns.put(`${this.jwtConfig.departmentOfHealthUpdateEndPoint}`, data);
	}

	deleteDepartmentOfHealth(id) {
		return this.axiosIns.delete(`${this.jwtConfig.departmentOfHealthDeleteEndPoint}/${id}`);
	}

	getUsersAdminDepartmentOfHealth(params) {
		return this.axiosIns.get(this.jwtConfig.getUsersAdminDepartmentOfHealthEndPoint, { params });
	}

	createUserAdminDepartmentOfHealth(data) {
		return this.axiosIns.post(this.jwtConfig.createUserAdminDepartmentOfHealthEndPoint, data);
	}

	updateUserAdminDepartmentOfHealth(data) {
		return this.axiosIns.put(`${this.jwtConfig.updateUserAdminDepartmentOfHealthEndPoint}`, data);
	}

	deleteUserAdminDepartmentOfHealth(id) {
		return this.axiosIns.delete(`${this.jwtConfig.deleteUserAdminDepartmentOfHealthEndPoint}/${id}`);
	}

	// Medical Facility
	getFacilities(...args) {
		return this.axiosIns.post(this.jwtConfig.facilityListEndPoint, ...args);
	}

	getFacilityAdmins(...args) {
		return this.axiosIns.post(this.jwtConfig.facilityAdminListEndPoint, ...args);
	}

	createFacility(data) {
		return this.axiosIns.post(this.jwtConfig.facilityCreateEndPoint, data);
	}

	createFacilityAdmin(data) {
		return this.axiosIns.post(this.jwtConfig.facilityAdminCreateEndPoint, data);
	}

	updateFacility(data) {
		return this.axiosIns.put(`${this.jwtConfig.facilityUpdateEndPoint}/${data._id}`, data);
	}

	updateFacilityAdmin(data) {
		return this.axiosIns.put(`${this.jwtConfig.facilityAdminUpdateEndPoint}/${data._id}`, data);
	}

	deleteFacility(id) {
		return this.axiosIns.delete(`${this.jwtConfig.facilityDeleteEndPoint}/${id}`);
	}

	// Pharmacy
	getPharmacies(...args) {
		return this.axiosIns.post(this.jwtConfig.pharmacyListEndPoint, ...args);
	}

	createPharmacy(data) {
		return this.axiosIns.post(this.jwtConfig.pharmacyCreateEndPoint, data);
	}

	updatePharmacy(data) {
		return this.axiosIns.put(`${this.jwtConfig.pharmacyUpdateEndPoint}/${data._id}`, data);
	}

	deletePharmacy(id) {
		return this.axiosIns.delete(`${this.jwtConfig.pharmacyDeleteEndPoint}/${id}`);
	}

	// Attendance
	getAttendances(params) {
		return this.axiosIns.get(this.jwtConfig.attendanceListEndPoint, { params });
	}

	exportAttendances(...args) {
		return this.axiosIns.post(this.jwtConfig.attendanceExportEndPoint, ...args, {
			responseType: 'blob',
		});
	}

	// Meeting
	getMeetings(params) {
		return this.axiosIns.get(this.jwtConfig.meetingListEndPoint, { params });
	}

	getMeetingsFromWorkLog(workLogId) {
		return this.axiosIns.post(this.jwtConfig.meetingListFromWorkLogEndPoint, { workLogId });
	}

	// Prescription
	getPrescriptions(params) {
		return this.axiosIns.get(this.jwtConfig.prescriptionListEndPoint, { params });
	}

	// Advertisement
	getAdvertisements(...args) {
		return this.axiosIns.post(this.jwtConfig.advertisementListEndPoint, ...args);
	}

	createAdvetisement(data) {
		return this.axiosIns.post(this.jwtConfig.advertisementCreateEndPoint, data);
	}

	updateAdvetisement(data) {
		return this.axiosIns.put(`${this.jwtConfig.advertisementUpdateEndPoint}/${data._id}`, data);
	}

	deleteAdvetisement(id) {
		return this.axiosIns.delete(`${this.jwtConfig.advertisementUpdateEndPoint}/${id}`);
	}

	// Payment
	getPayments(...args) {
		return this.axiosIns.post(this.jwtConfig.paymentListEndPoint, ...args);
	}

	// Promotions
	getPromotions(...args) {
		return this.axiosIns.post(this.jwtConfig.promotionListEndPoint, ...args);
	}

	createDiscount(data) {
		return this.axiosIns.post(this.jwtConfig.promotionCreateEndPoint, data);
	}

	updateDiscount(data) {
		return this.axiosIns.put(`${this.jwtConfig.promotionUpdateEndPoint}/${data._id}`, data);
	}

	deleteDiscount(id) {
		return this.axiosIns.delete(`${this.jwtConfig.promotionDeleteEndPoint}/${id}`);
	}

	// Voucher
	getVouchers(...args) {
		const role = getUserRole();
		if (role.name === 'medical_facility') {
			return this.axiosIns.post(this.jwtConfig.voucherListForMedicalFacilityEndPoint, ...args);
		}
		return this.axiosIns.post(this.jwtConfig.voucherListEndPoint, ...args);
	}

	createVoucher(data) {
		return this.axiosIns.post(this.jwtConfig.voucherCreateEndPoint, data);
	}

	updateVoucher(data) {
		return this.axiosIns.put(`${this.jwtConfig.voucherUpdateEndPoint}/${data._id}`, data);
	}

	// Code
	getCodes(...args) {
		return this.axiosIns.post(this.jwtConfig.codeListEndPoint, ...args);
	}

	// Pricing
	getPricings(...args) {
		return this.axiosIns.post(this.jwtConfig.pricingListEndPoint, ...args);
	}

	getSuggestPricing(...args) {
		return this.axiosIns.post(this.jwtConfig.pricingSuggestEndPoint, ...args);
	}

	createPricing(data) {
		return this.axiosIns.post(this.jwtConfig.pricingCreateEndPoint, data);
	}

	updatePricing(data) {
		return this.axiosIns.put(`${this.jwtConfig.pricingUpdateEndPoint}/${data._id}`, data);
	}

	deletePricing(id) {
		return this.axiosIns.delete(`${this.jwtConfig.pricingDeleteEndPoint}/${id}`);
	}

	// Department
	getDepartments(...args) {
		return this.axiosIns.post(this.jwtConfig.departmentListEndPoint, ...args);
	}

	getSuggestDepartments(data) {
		return this.axiosIns.post(this.jwtConfig.departmentSuggestEndPoint, data);
	}

	createDepartment(data) {
		return this.axiosIns.post(this.jwtConfig.departmentCreateEndPoint, data);
	}

	updateDepartment(data) {
		return this.axiosIns.put(`${this.jwtConfig.departmentUpdateEndPoint}/${data._id}`, data);
	}

	deleteDepartment(id) {
		return this.axiosIns.delete(`${this.jwtConfig.departmentDeleteEndPoint}/${id}`);
	}

	// Specialist
	getSpecialists(...args) {
		return this.axiosIns.post(this.jwtConfig.specialistListEndPoint, ...args);
	}

	getSuggestSpecialists(...args) {
		return this.axiosIns.post(this.jwtConfig.specialistSuggestEndPoint, ...args);
	}

	createSpecialist(data) {
		return this.axiosIns.post(this.jwtConfig.specialistCreateEndPoint, data);
	}

	updateSpecialist(data) {
		return this.axiosIns.put(`${this.jwtConfig.specialistUpdateEndPoint}/${data._id}`, data);
	}

	deleteSpecialist(id) {
		return this.axiosIns.delete(`${this.jwtConfig.specialistDeleteEndPoint}/${id}`);
	}

	// Blood
	getBloods(...args) {
		return this.axiosIns.post(this.jwtConfig.bloodListEndPoint, ...args);
	}

	createBlood(data) {
		return this.axiosIns.post(this.jwtConfig.bloodCreateEndPoint, data);
	}

	updateBlood(data) {
		return this.axiosIns.put(`${this.jwtConfig.bloodUpdateEndPoint}/${data._id}`, data);
	}

	deleteBlood(id) {
		return this.axiosIns.delete(`${this.jwtConfig.bloodDeleteEndPoint}/${id}`);
	}

	// Service
	getServices(...args) {
		return this.axiosIns.post(this.jwtConfig.serviceListEndPoint, ...args);
	}

	createService(data) {
		return this.axiosIns.post(this.jwtConfig.serviceCreateEndPoint, data);
	}

	updateService(data) {
		return this.axiosIns.put(`${this.jwtConfig.serviceUpdateEndPoint}/${data._id}`, data);
	}

	deleteService(id) {
		return this.axiosIns.delete(`${this.jwtConfig.serviceDeleteEndPoint}/${id}`);
	}

	// Disease
	getDiseases(...args) {
		return this.axiosIns.post(this.jwtConfig.diseaseListEndPoint, ...args);
	}

	createDisease(data) {
		return this.axiosIns.post(this.jwtConfig.diseaseCreateEndPoint, data);
	}

	updateDisease(data) {
		return this.axiosIns.put(`${this.jwtConfig.diseaseUpdateEndPoint}/${data._id}`, data);
	}

	deleteDisease(id) {
		return this.axiosIns.delete(`${this.jwtConfig.diseaseDeleteEndPoint}/${id}`);
	}

	// Drug
	getDrugs(...args) {
		return this.axiosIns.post(this.jwtConfig.drugListEndPoint, ...args);
	}

	createDrug(data) {
		return this.axiosIns.post(this.jwtConfig.drugCreateEndPoint, data);
	}

	updateDrug(data) {
		return this.axiosIns.put(`${this.jwtConfig.drugUpdateEndPoint}/${data._id}`, data);
	}

	deleteDrug(id) {
		return this.axiosIns.delete(`${this.jwtConfig.drugDeleteEndPoint}/${id}`);
	}

	// FAQ
	getFaqs(...args) {
		return this.axiosIns.post(this.jwtConfig.faqListEndPoint, ...args);
	}

	createFaq(data) {
		return this.axiosIns.post(this.jwtConfig.faqCreateEndPoint, data);
	}

	updateFaq(data) {
		return this.axiosIns.put(`${this.jwtConfig.faqUpdateEndPoint}/${data._id}`, data);
	}

	deleteFaq(id) {
		return this.axiosIns.delete(`${this.jwtConfig.faqDeleteEndPoint}/${id}`);
	}

	// File
	uploadSingleFile(...args) {
		return this.axiosIns.post(this.jwtConfig.fileUploadSingleEndPoint, ...args);
	}

	// Exp
	getExps(...args) {
		return this.axiosIns.post(this.jwtConfig.expListEndPoint, ...args);
	}

	createExp(...args) {
		return this.axiosIns.post(this.jwtConfig.expCreateEndPoint, ...args);
	}

	updateExp(data) {
		return this.axiosIns.put(`${this.jwtConfig.expUpdateEndPoint}/${data._id}`, data);
	}

	deleteExp(id) {
		return this.axiosIns.delete(`${this.jwtConfig.expDeleteEndPoint}/${id}`);
	}

	// Education
	getEducations(...args) {
		return this.axiosIns.post(this.jwtConfig.educationListEndPoint, ...args);
	}

	createEducation(...args) {
		return this.axiosIns.post(this.jwtConfig.educationCreateEndPoint, ...args);
	}

	updateEducation(data) {
		return this.axiosIns.put(`${this.jwtConfig.educationUpdateEndPoint}/${data._id}`, data);
	}

	deleteEducation(id) {
		return this.axiosIns.delete(`${this.jwtConfig.educationDeleteEndPoint}/${id}`);
	}

	// Configuration
	getConfigurations(...args) {
		return this.axiosIns.post(this.jwtConfig.configurationListEndPoint, ...args);
	}

	createConfiguration(...args) {
		return this.axiosIns.post(this.jwtConfig.configurationCreateEndPoint, ...args);
	}

	updateConfiguration(data) {
		return this.axiosIns.put(`${this.jwtConfig.configurationUpdateEndPoint}/${data._id}`, data);
	}

	deleteConfiguration(id) {
		return this.axiosIns.delete(`${this.jwtConfig.configurationDeleteEndPoint}/${id}`);
	}

	// Zoom User
	getZoomUsers(params) {
		return this.axiosIns.get(this.jwtConfig.zoomUserListEndPoint, { params });
	}

	synchronizeZoomUsers() {
		return this.axiosIns.get(this.jwtConfig.zoomUserSynchronizationEndPoint);
	}

	createZoomUser(...args) {
		return this.axiosIns.post(this.jwtConfig.zoomUserCreateEndPoint, ...args);
	}

	updateZoomUser(data) {
		return this.axiosIns.put(this.jwtConfig.zoomUserUpdateEndPoint, data);
	}

	deleteZoomUser(id) {
		return this.axiosIns.delete(`${this.jwtConfig.zoomUserDeleteEndPoint}/${id}`);
	}

	// Partner
	getPartners(params) {
		return this.axiosIns.get(this.jwtConfig.partnerListEndPoint, { params });
	}

	createPartner(...args) {
		return this.axiosIns.post(this.jwtConfig.partnerCreateEndPoint, ...args);
	}

	updatePartner(data) {
		return this.axiosIns.put(this.jwtConfig.partnerUpdateUpdateEndPoint, data);
	}

	deletePartner(id) {
		return this.axiosIns.delete(`${this.jwtConfig.partnerDeleteEndPoint}/${id}`);
	}

	// role
	createRole(...args) {
		return this.axiosIns.post(this.jwtConfig.roleCreateEndpoint, ...args);
	}

	updateRole(data) {
		return this.axiosIns.put(`${this.jwtConfig.roleUpdateEndpoint}/${data.id}`, data.data);
	}

	getRoles(data) {
		return this.axiosIns.post(this.jwtConfig.roleListEndpoint, data);
	}

	getRoleDetail(id) {
		return this.axiosIns.get(`${this.jwtConfig.roleDetailEndpoint}/${id}`);
	}

	getRoleAbilities(id) {
		return this.axiosIns.get(`${this.jwtConfig.roleAbilitiesEndpoint}/${id}`);
	}

	deleteRole(id) {
		return this.axiosIns.delete(`${this.jwtConfig.roleDeleteEndpoint}/${id}`);
	}

	// manager
	createManager(...args) {
		return this.axiosIns.post(this.jwtConfig.managerCreateEndpoint, ...args);
	}

	updateManager(data) {
		return this.axiosIns.put(`${this.jwtConfig.managerUpdateEndpoint}/${data.id}`, data);
	}

	getManagers(params) {
		return this.axiosIns.get(this.jwtConfig.managerListEndpoint, {
			params,
		});
	}

	deleteManagers(data) {
		return this.axiosIns.delete(this.jwtConfig.managerDeleteEndpoint, { data });
	}

	getManagerDetail(params) {
		return this.axiosIns.get(this.jwtConfig.managerDetailEndpoint, {
			params,
		});
	}

	// Permission
	getPermissions(data) {
		return this.axiosIns.post(this.jwtConfig.permissionListEndPoint, data);
	}

	// rating
	getRatings(params) {
		return this.axiosIns.get(this.jwtConfig.ratingListEndpoint, { params });
	}

	// setting department of health
	getDepartmentOfHealthSetting() {
		return this.axiosIns.get(this.jwtConfig.departmentOfhealthSettingGetEndPoint);
	}

	updateDepartmentOfHealthSetting(data) {
		return this.axiosIns.post(this.jwtConfig.departmentOfhealthSettingSaveEndPoint, data);
	}

	// member
	getMembers(customerId) {
		return this.axiosIns.get(`${this.jwtConfig.memberListOfCustomer}/${customerId}`);
	}
}
