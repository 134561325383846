export default [
  {
    path: '/doctor/list',
    name: 'doctor-list',
    component: () => import('@/views/user/doctor/list/DoctorList.vue'),
    meta: {
      resource: 'Doctor',
      action: 'read',
    },
  },
  {
    path: '/patient/list',
    name: 'patient-list',
    component: () => import('@/views/user/patient/list/PatientList.vue'),
    meta: {
      resource: 'Patient',
      action: 'read',
    },
  },

];
