export default [
  {
    path: '/partner/list',
    name: 'partner-list',
    component: () => import('@/views/gateway/partner/list/PartnerList.vue'),
    meta: {
      resource: 'Setting',
      action: 'read',
    },
  },
  {
    path: '/account/list',
    name: 'account-list',
    component: () => import('@/views/gateway/account/list/AccountList.vue'),
    meta: {
      resource: 'Setting',
      action: 'read',
    },
  },
];
