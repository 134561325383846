// eslint-disable-next-line import/no-cycle
import useJwt from './jwt/useJwt';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem('userData')
    && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => {
  const json = JSON.parse(localStorage.getItem('userData'));

  return json;
};

export const getUserProvince = () => {
  const json = JSON.parse(localStorage.getItem('userData'));
  if (json && json.province) {
    return { _id: json.province, name: json.province };
  }
  return null;
};

export const getUserDistrict = () => {
  const json = JSON.parse(localStorage.getItem('userData'));
  if (json && json.medical_facility && json.medical_facility.districtObject) {
    return json.medical_facility.districtObject;
  }
  return null;
};

export const getUserRole = () => {
  const json = JSON.parse(localStorage.getItem('userData'));

  if (json && json.role) {
    return json.role;
  }

  return null;
};

export const isMedicalFacility = () => {
  const role = getUserRole();
  if (role && role.name === 'medical_facility') {
    return true;
  }
  return false;
};

export const isHealthCentre = () => {
  const userData = getUserData();
  if (userData && userData.role && userData.role.name === 'medical_facility') {
    if (userData.medical_facility && userData.medical_facility.is_health_centre) {
      return true;
    }
  }
  return false;
};

export const isDepartmentOfHealth = () => {
  const role = getUserRole();
  if (role && role.name === 'department_of_health') {
    return true;
  }
  return false;
};

export const getProvinceForMedicalFacility = () => {
  const role = getUserRole();
  if (role && role.name === 'department_of_health') {
    return true;
  }
  return false;
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/';
  if (userRole === 'client') return { name: 'access-control' };
  return { name: 'auth-login' };
};

export const getUserAbilities = role => {
  if (!role) return [];

  const { permissions, name } = role;
  if (!permissions) return [];
  const permissionNames = permissions.map(_obj => _obj.name.split('.')[0]);

  const abilities = [
    {
      subject: 'Miscellaneous',
      action: 'read',
    },
    {
      subject: 'Dashboard',
      action: 'read',
    },
    {
      subject: 'Profile',
      action: 'read',
    },
    {
      subject: 'Operation',
      action: 'manage',
    },
    {
      subject: 'Review',
      action: 'read',
    },
    {
      subject: 'Attendance',
      action: 'read',
    },
    {
      subject: 'History',
      action: 'read',
    },
    {
      subject: 'Prescription',
      action: 'read',
    },
  ];

  // if (role.name === 'medical_facility') {
  //   abilities.push({
  //     subject: 'Patient',
  //     action: 'read',
  //   });
  // }

  if (permissionNames.includes('doctor')) {
    abilities.push({
      subject: 'Doctor',
      action: 'read',
    });
    abilities.push({
      subject: 'User',
      action: 'read',
    });
    if (name === 'medical_facility') {
      abilities.push({
        subject: 'Doctor',
        action: 'create',
      });
      abilities.push({
        subject: 'Doctor',
        action: 'delete',
      });
      abilities.push({
        subject: 'Doctor',
        action: 'update',
      });
    }
  }

  if (permissionNames.includes('customer')) {
    abilities.push({
      subject: 'Patient',
      action: 'read',
    });
    abilities.push({
      subject: 'User',
      action: 'read',
    });
  }
  if (permissionNames.includes('medical-facility') && role.name !== 'medical_facility') {
    abilities.push({
      subject: 'Facility',
      action: 'read',
    });
    abilities.push({
      subject: 'Place',
      action: 'manage',
    });
  }
  if (permissionNames.includes('pharmacy')) {
    abilities.push({
      subject: 'Pharmacy',
      action: 'read',
    });
    abilities.push({
      subject: 'Place',
      action: 'manage',
    });
  }
  if (permissionNames.includes('work-log')) {
    abilities.push({
      subject: 'Attendance',
      action: 'read',
    });
    abilities.push({
      subject: 'Operation',
      action: 'manage',
    });
  }
  if (permissionNames.includes('meeting')) {
    abilities.push({
      subject: 'History',
      action: 'read',
    });
    abilities.push({
      subject: 'Operation',
      action: 'manage',
    });
  }
  if (permissionNames.includes('prescription')) {
    abilities.push({
      subject: 'Prescription',
      action: 'read',
    });
    abilities.push({
      subject: 'Operation',
      action: 'manage',
    });
  }

  if (permissionNames.includes('pricing')) {
    abilities.push({
      subject: 'Pricing',
      action: 'read',
    });
    abilities.push({
      subject: 'Subscription',
      action: 'manage',
    });
  }
  if (permissionNames.includes('voucher')) {
    abilities.push({
      subject: 'Voucher',
      action: 'read',
    });
    abilities.push({
      subject: 'Subscription',
      action: 'manage',
    });
  }
  if (permissionNames.includes('promotion')) {
    abilities.push({
      subject: 'Discount',
      action: 'read',
    });
    abilities.push({
      subject: 'Subscription',
      action: 'manage',
    });
  }
  if (permissionNames.includes('voucher')) {
    abilities.push({
      subject: 'Voucher',
      action: 'read',
    });
    abilities.push({
      subject: 'Subscription',
      action: 'manage',
    });
  }
  if (permissionNames.includes('payment')) {
    abilities.push({
      subject: 'Payment',
      action: 'read',
    });
    abilities.push({
      subject: 'Subscription',
      action: 'manage',
    });
  }
  if (permissionNames.includes('pricing-code')) {
    abilities.push({
      subject: 'Code',
      action: 'read',
    });
    abilities.push({
      subject: 'Subscription',
      action: 'manage',
    });
  }

  // setting
  if (permissionNames.includes('advertisement')) {
    abilities.push({
      subject: 'SettingAdvertisement',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('configuration')) {
    abilities.push({
      subject: 'SettingConfiguration',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('specialist_category')) {
    abilities.push({
      subject: 'Department',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('specialist_sub_category')) {
    abilities.push({
      subject: 'Specialist',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('drug')) {
    abilities.push({
      subject: 'Drug',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('blood_type')) {
    abilities.push({
      subject: 'Blood',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('diseases')) {
    abilities.push({
      subject: 'Disease',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('providing_service')) {
    abilities.push({
      subject: 'Service',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('pre-medical-question')) {
    abilities.push({
      subject: 'Faq',
      action: 'read',
    });
    abilities.push({
      subject: 'Setting',
      action: 'manage',
    });
  }
  if (permissionNames.includes('department-of-health')) {
    abilities.push({
      subject: 'Facility',
      action: 'manage',
    });
    abilities.push({
      subject: 'DepartmentOfHealth',
      action: 'manage',
    });
  }

  return abilities;
};
