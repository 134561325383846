export default [
  {
    path: '/attendance/list',
    name: 'attendance-list',
    component: () => import('@/views/operation/attendance/list/AttendanceList.vue'),
    meta: {
      resource: 'Attendance',
      action: 'read',
    },
  },
  {
    path: '/history/list',
    name: 'history-list',
    component: () => import('@/views/operation/history/list/HistoryList.vue'),
    meta: {
      resource: 'History',
      action: 'read',
    },
  },
  {
    path: '/prescription/list',
    name: 'prescription-list',
    component: () => import('@/views/operation/prescription/list/PrescriptionList.vue'),
    meta: {
      resource: 'Prescription',
      action: 'read',
    },
  },
  {
    path: '/review/list',
    name: 'review-list',
    component: () => import('@/views/operation/reviews/list/ReviewList.vue'),
    meta: {
      resource: 'Review',
      action: 'read',
    },
  },

];
